import axios, { AxiosError } from "axios";
import { ChangePanelTypeCommand } from "./commands/changePanelTypeCommand";
import { CreateCustomCornerCommand } from "./commands/createCustomCornerCommand";
import { CreateCustomPanelCommand } from "./commands/createCustomPanelCommand";
import { CreateCustomZShapedPanelCommand } from "./commands/createCustomZShapedPanelCommand";
import { CreatePanelTypeCommand } from "./commands/createPanelTypeCommand";
import { PublishCustomCornerTypeCommand, PublishCustomPanelTypeCommand, PublishCustomZShapePanelTypeCommand }
    from "./commands/publishCustomPanelTypeCommand";
import { UpdateCustomCornerOffsetCommand } from "./commands/updateCustomCornerOffsetCommand";
import { UpdateCustomPanelOffsetCommand } from "./commands/updateCustomPanelOffsetCommand";
import { UpdateElementCommand } from "./commands/updateElementCommand";
import { UpdateHooksCommand, UpdateHooksCommandBatch } from "./commands/updateHooksCommand";
import { UpdatePanelTypeCommand } from "./commands/updatePanelTypeCommand";
import { BasicItemResponse, BasicItemsResponse, BasicResponse } from "./responses/basicResponses";
import { ICornerPanelSource, IStudioCornerPanelSource } from "./responses/cornerPanelSource";
import { ICustomCornerSource } from "./responses/customCornerSource";
import { ICustomPanelSource } from "./responses/customPanelSource";
import { ICustomCornerType, ICustomPanelType, ICustomPanelTypeCreatedResponse, ICustomZShapeType }
    from "./responses/customPanelTypes";
import { ICustomZShapedPanelSource } from "./responses/customZShapedPanelSource";
import { FontGeometry } from "./responses/fontGeometry";
import { IForgeToken } from "./responses/forgeToken";
import { IInsulatedGlassUnit } from "./responses/insulatedGlassUnit";
import { IModelCorner } from "./responses/modelCorner";
import { IPanelGeneratedDrawingDto } from "./responses/panelGeneratedDrawingDto";
import { IPanelGeneratedModelDto } from "./responses/panelGeneratedModelDto";
import { IPanelSource, IStudioPanelSource } from "./responses/panelSource";
import { IFamily } from "./responses/panelType";
import { PanelTypeGenerationStartedResponse } from "./responses/panelTypeGenerationStartedResponse";
import { IRevitFamiliesGeneratingJob, RevitFacadeGeneratedModel, RevitGeneratedModel }
    from "./responses/revitGeneratedModel";
import { ISourceModel } from "./responses/sourceModel";
import { StickyNote, StickyNoteSourceType } from "./responses/stickyNote";
import { SystemSettings } from "./responses/systemSettings";
import { IWallFace } from "./responses/wallFace";

const AuthorizationHeader = "Authorization";
const unknownFailureMessage = "Server error. Repeat operation later";

class Repository {
    private accessToken: string | undefined;

    async getForgeToken(): Promise<BasicItemResponse<IForgeToken>> {
        try {
            const response = await axios.get<BasicItemResponse<IForgeToken>>("/api/forge/token");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findModel(id: string): Promise<BasicItemResponse<ISourceModel>> {
        try {
            const response = await axios.get<BasicItemResponse<ISourceModel>>(`/api/models/${id}`);

            return response.data;

        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async loadInsulatedGlassUnits(): Promise<BasicItemsResponse<IInsulatedGlassUnit>> {
        try {
            const response = await axios.get<BasicItemsResponse<IInsulatedGlassUnit>>("https://studio.dextall.com/insulated-glass-units");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelSystemSettings(id: string): Promise<BasicItemResponse<SystemSettings>> {
        try {
            const response = await axios.get<BasicItemResponse<SystemSettings>>(`https://studio.dextall.com/models/${id}/system-settings`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findModelFaces(id: string): Promise<BasicItemsResponse<IWallFace>> {
        try {
            const response = await axios.get<BasicItemsResponse<IWallFace>>(`/api/models/${id}/wall-faces`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCorners(id: string): Promise<BasicItemsResponse<IModelCorner>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelCorner>>(`/api/models/${id}/wall-corners`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelPanels(id: string): Promise<BasicItemsResponse<IPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelSource>>(`/api/models/${id}/panels`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCornerPanels(id: string): Promise<BasicItemsResponse<ICornerPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICornerPanelSource>>(`/api/models/${id}/corners`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCustomPanels(id: string): Promise<BasicItemsResponse<ICustomPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomPanelSource>>(`/api/models/${id}/custom-panels`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCustomCorners(id: string): Promise<BasicItemsResponse<ICustomCornerSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomCornerSource>>(
                `/api/models/${id}/custom-corners`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCustomZShapedPanels(id: string): Promise<BasicItemsResponse<ICustomZShapedPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomZShapedPanelSource>>(
                `/api/models/${id}/custom-z-panels`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelPanelFamilies(id: string): Promise<BasicItemsResponse<IFamily>> {
        try {
            const response = await axios.get<BasicItemsResponse<IFamily>>(`/api/models/${id}/panel-families`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCornerPanelFamilies(id: string): Promise<BasicItemsResponse<IFamily>> {
        try {
            const response = await axios.get<BasicItemsResponse<IFamily>>(`/api/models/${id}/corner-families`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async updatePanel(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCorner(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updatePanelTypeHooks(panelTypeId: string, command: UpdateHooksCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panel-types/${panelTypeId}/hooks`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCornerPanelTypeHooks(panelTypeId: string, command: UpdateHooksCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corner-types/${panelTypeId}/hooks`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updatePanelTypeHooksBatch(command: UpdateHooksCommandBatch): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/panel-types/hooks-batch", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCornerPanelTypeHooksBatch(command: UpdateHooksCommandBatch): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/corner-types/hooks-batch", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updatePanelType(typeId: string, command: UpdatePanelTypeCommand) {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panel-types/${typeId}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCornerPanelType(typeId: string, command: UpdatePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corner-types/${typeId}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async duplicatePanelType(familyId: string, command: CreatePanelTypeCommand): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/families/${familyId}/duplicate-panel-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async duplicateCornerType(familyId: string, command: CreatePanelTypeCommand): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/families/${familyId}/duplicate-corner-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async setPanelType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${panelId}/switch-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async setCornerPanelType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${panelId}/switch-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async setPanelCustomType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${panelId}/switch-custom-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async setCornerCustomType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${panelId}/switch-custom-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async resetPanelCustomType(panelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${panelId}/reset-custom-type`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async resetCornerCustomType(panelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${panelId}/reset-custom-type`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async findPanelTypeGeneratedModel(panelTypeId: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/panel-types/${panelTypeId}/generated-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanelGeneratedModel(id: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/generated/panel-models/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllPanelGeneratedModels(modelId: string): Promise<BasicItemsResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelGeneratedModelDto>>(`/api/all-types/${modelId}/generated-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCornerPanelTypeGeneratedModel(panelTypeId: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/corner-types/${panelTypeId}/generated-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findCornerPanelGeneratedModel(id: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/generated/corner-models/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanelGeneratedDrawing(id: string): Promise<BasicItemResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedDrawingDto>>(`/api/generated/panel-drawings/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findCornerPanelGeneratedDrawing(id: string): Promise<BasicItemResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedDrawingDto>>(`/api/generated/corner-drawings/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanelTypeModelDrawings(panelTypeModelId: string): Promise<BasicItemsResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelGeneratedDrawingDto>>(`/api/generated/panel-models/${panelTypeModelId}/drawings`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCornerPanelTypeModelDrawings(panelTypeModelId: string): Promise<BasicItemsResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelGeneratedDrawingDto>>(`/api/generated/corner-models/${panelTypeModelId}/drawings`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findHooksGeneratedModel(sourceModelId: string): Promise<BasicItemResponse<RevitGeneratedModel>> {
        try {
            const response = await axios.get<BasicItemResponse<RevitGeneratedModel>>(`/api/models/${sourceModelId}/hooks-model`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async loadThreeJsFont(): Promise<FontGeometry> {
        const response = await axios.get<FontGeometry>("/font-geometry.json");

        return response.data;
    }

    async loadCustomComponentMaterialVertexShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customComponentVertex.glsl");

        return response.data;
    }

    async loadCustomComponentMaterialFragmentShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customComponentFragment.glsl");

        return response.data;
    }

    async loadCustomCornerComponentMaterialVertexShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customCornerComponentVertex.glsl");

        return response.data;
    }

    async loadCustomCornerComponentMaterialFragmentShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customCornerComponentFragment.glsl");

        return response.data;
    }

    async startPanelTypeModelGeneration(panelTypeId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/design-automation/generation/panels/${panelTypeId}/start`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startCornerPanelTypeModelGeneration(panelTypeId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/design-automation/generation/corners/${panelTypeId}/start`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startEntireModelGeneration(modelId: string, regenerateExistingTypes = false): Promise<BasicItemResponse<PanelTypeGenerationStartedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<PanelTypeGenerationStartedResponse>>("/api/design-automation/generation/model", {
                modelId,
                regenerateExistingTypes
            });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startRevitHooksModelGeneration(modelId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/design-automation/generation/hooks-model/${modelId}/start`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createGeneratedModelDownloadableUrl(id: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.get<BasicItemResponse<string>>(`/api/generated/${id}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createHooksGeneratedModelDownloadableUrl(id: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.get<BasicItemResponse<string>>(`/api/generated/hooks-models/${id}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllCustomPanelTypes(): Promise<BasicItemsResponse<ICustomPanelType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomPanelType>>("/api/custom-panel-types/all");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findPublishedCustomPanelTypes(): Promise<BasicItemsResponse<ICustomPanelType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomPanelType>>("/api/custom-panel-types/published");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCustomPanelType(id: string): Promise<BasicItemResponse<ICustomPanelType>> {
        try {
            const response = await axios.get<BasicItemResponse<ICustomPanelType>>(`/api/custom-panel-types/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllCustomCornerTypes(): Promise<BasicItemsResponse<ICustomCornerType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomCornerType>>("/api/custom-corner-types/all");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findPublishedCustomCornerTypes(): Promise<BasicItemsResponse<ICustomCornerType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomCornerType>>("/api/custom-corner-types/published");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCustomCornerType(id: string): Promise<BasicItemResponse<ICustomCornerType>> {
        try {
            const response = await axios.get<BasicItemResponse<ICustomCornerType>>(`/api/custom-corner-types/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllCustomZShapedTypes(): Promise<BasicItemsResponse<ICustomZShapeType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomZShapeType>>("/api/custom-z-shaped-types/all");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findPublishedCustomZShapedTypes(): Promise<BasicItemsResponse<ICustomZShapeType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomZShapeType>>("/api/custom-z-shaped-types/published");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCustomZShapedType(id: string): Promise<BasicItemResponse<ICustomZShapeType>> {
        try {
            const response = await axios.get<BasicItemResponse<ICustomZShapeType>>(`/api/custom-z-shaped-types/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async publishCustomPanelTypeComponent(command: PublishCustomPanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/custom-panel-types/publish", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async publishCustomCornerTypeComponent(command: PublishCustomCornerTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/custom-corner-types/publish", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async publishCustomZShapePanelType(command: PublishCustomZShapePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/custom-z-shaped-types/publish", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomPanelType(name: string): Promise<BasicItemResponse<ICustomPanelTypeCreatedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelTypeCreatedResponse>>("/api/custom-panel-types/create", { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createCustomCornerType(name: string): Promise<BasicItemResponse<ICustomPanelTypeCreatedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelTypeCreatedResponse>>("/api/custom-corner-types/create", { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createCustomZShapedType(name: string): Promise<BasicItemResponse<ICustomPanelTypeCreatedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelTypeCreatedResponse>>("/api/custom-z-shaped-types/create", { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startCustomPanelTypeProcessing(id: string, assemblyFileName: string): Promise<BasicResponse> {
        const command = { id, fileName: assemblyFileName };

        try {
            const response = await axios.post<BasicResponse>("/api/custom-panel-types/process", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startCustomCornerTypeProcessing(id: string, assemblyFileName: string): Promise<BasicResponse> {
        const command = { id, fileName: assemblyFileName };

        try {
            const response = await axios.post<BasicResponse>("/api/custom-corner-types/process", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startCustomZShapedTypeProcessing(id: string, assemblyFileName: string): Promise<BasicResponse> {
        const command = { id, fileName: assemblyFileName };

        try {
            const response = await axios.post<BasicResponse>("/api/custom-z-shaped-types/process", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomPanel(command: CreateCustomPanelCommand): Promise<BasicItemResponse<ICustomPanelSource>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelSource>>("/api/custom-panels/create", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async changeCustomPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${panelId}/switch-type`, { targetTypeId });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    /** @deprecated use updateCustomPanel instead */
    async updateCustomPanelElementName(id: string,
        command: Omit<UpdateElementCommand, "installationSequence">): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${id}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomPanel(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomPanelOffset(id: string, command: UpdateCustomPanelOffsetCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${id}/update-offset`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeCustomPanel(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/custom-panels/${id}/remove`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomCorner(command: CreateCustomCornerCommand): Promise<BasicItemResponse<ICustomCornerSource>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomCornerSource>>("/api/custom-corners/create", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async changeCustomCornerType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${panelId}/switch-type`, { targetTypeId });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    /** @deprecated use updateCustomCorner instead */
    async updateCustomCornerElementName(id: string,
        command: Omit<UpdateElementCommand, "installationSequence">): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${id}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomCorner(id: string, command: UpdateElementCommand) {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomCornerOffset(id: string, command: UpdateCustomCornerOffsetCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${id}/update-offset`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeCustomCorner(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/custom-corners/${id}/remove`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomZShapedPanel(command: CreateCustomZShapedPanelCommand): Promise<BasicItemResponse<ICustomZShapedPanelSource>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomZShapedPanelSource>>("/api/custom-z-panels/create", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async changeCustomZShapedPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${panelId}/switch-type`, { targetTypeId });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    /** @deprecated use updateCustomZShapedPanel instead */
    async changeCustomZShapedPanelElementName(id: string,
        command: Omit<UpdateElementCommand, "installationSequence">): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${id}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomZShapedPanel(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomZShapedPanelOffset(id: string, command: UpdateCustomCornerOffsetCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${id}/update-offset`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeCustomZShapedPanel(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/custom-z-panels/${id}/remove`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startStickyNoteGeneration(type: StickyNoteSourceType, panelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.post<BasicResponse>(`/api/${type}/${panelId}/generate-sticky-note`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startModelStickyNoteGeneration(modelId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/models/${modelId}/sticky-notes/generate`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async getStickyNote(type: StickyNoteSourceType | "models", panelId: string): Promise<BasicItemResponse<StickyNote>> {
        try {
            const response = await axios.post<BasicItemResponse<StickyNote>>(`/api/${type}/${panelId}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async uploadFile(file: File, url: string): Promise<BasicResponse> {
        const fileName = file.name;

        try {
            await axios({
                url,
                method: "put",
                data: file,
                headers: {
                    "Content-Disposition": "attachment; filename=\"" + encodeURIComponent(fileName) + "\""
                }
            });

            return { isSuccess: true, message: null };
        } catch (e) {
            return { isSuccess: false, message: "An error occured during file upload process. Try again later!" };
        }
    }

    async findLatestFacadePanelModel(modelId: string): Promise<BasicItemResponse<RevitFacadeGeneratedModel>> {
        try {
            const response = await axios.get<BasicItemResponse<RevitFacadeGeneratedModel>>(
                `/api/models/${modelId}/latest-facade-panel-model`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createStudioMissingPanelTypes(modelId: string): Promise<BasicItemsResponse<IStudioPanelSource>> {
        try {
            const response = await axios.post<BasicItemsResponse<IStudioPanelSource>>(
                `https://studio.dextall.com/api/models/${modelId}/create-panel-types`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findStudioPanelsByIds(ids: string[]): Promise<BasicItemsResponse<IStudioPanelSource>> {
        try {
            const response = await axios.post<BasicItemsResponse<IStudioPanelSource>>(
                "https://studio.dextall.com/api/find-wall-panels-batch", ids);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findStudioCornerPanelsByIds(ids: string[]): Promise<BasicItemsResponse<IStudioCornerPanelSource>> {
        try {
            const response = await axios.post<BasicItemsResponse<IStudioCornerPanelSource>>(
                "https://studio.dextall.com/api/find-wall-corner-panels-batch", ids);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createStudioMissingCornerTypes(modelId: string): Promise<BasicItemsResponse<IStudioCornerPanelSource>> {
        try {
            const response = await axios.post<BasicItemsResponse<IStudioCornerPanelSource>>(
                `https://studio.dextall.com/api/models/${modelId}/create-corner-types`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createStudioMissingRevitFamilies(modelId: string, highResolutionFamilies: boolean)
        : Promise<BasicItemResponse<IRevitFamiliesGeneratingJob>> {
        try {
            const command = { modelId, highResolutionFamilies };

            const response = await axios.post<BasicItemResponse<IRevitFamiliesGeneratingJob>>(
                "https://studio.dextall.com/api/panel-types/create-model-revit-families",
                command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async checkStudioModelRevitFamiliesStatus(modelId: string, highResolutionFamilies: boolean)
        : Promise<BasicItemResponse<IRevitFamiliesGeneratingJob>> {
        try {
            const response = await axios.get<BasicItemResponse<IRevitFamiliesGeneratingJob>>(
                `https://studio.dextall.com/api/panel-types/model/${modelId}/status?highRes=${highResolutionFamilies}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createFacadeModel(modelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.post<BasicResponse>(`/api/facade-models/create/${modelId}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createFacadeModelDownloadableUrl(modelId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(
                `/api/facade-models/${modelId}/create-download-url`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, item: null, message: unknownFailureMessage };
        }
    }

    setAccessToken(value: string) {
        this.accessToken = value;
        axios.defaults.headers.common[AuthorizationHeader] = `Bearer ${value}`;
    }

    getAccessToken(): string {
        if (!this.accessToken)
            throw new Error("Repository was not initialized");

        return this.accessToken;
    }
}

export default new Repository();