import { BatchUpdatePanelTypeHooksCommand } from "../../../commands/updateHooksCommand";
import { BasicResponse } from "../../../responses/basicResponses";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { IPanelSource } from "../../../responses/panelSource";
import { Corner } from "./corner";
import { Panel } from "./panel";
import { PanelType } from "./panelType";
import repo from "../../../Repository";

export type ActionType = "create" | "update" | "delete";

export type EntityAction<T> = [T, ActionType];

export class EntityUpdater<T> {
    private readonly queue: EntityAction<T>[] = [];
    private handle?: number;

    constructor(
        private readonly createAction?: (item: T) => Promise<BasicResponse>,
        private readonly updateAction?: (item: T) => Promise<BasicResponse>,
        private readonly deleteAction?: (item: T) => Promise<BasicResponse>,
    ) { }

    push(item: T, action: ActionType) {
        this.queue.push([item, action]);

        if (this.handle === undefined) {
            this.handle = window.setTimeout(async () => this.flush());
        }
    }

    private async flush() {
        const items = [...this.queue];
        this.queue.length = 0;

        const failedItems: EntityAction<T>[] = [];

        for (const item of items) {
            const flushResult = await this.flushItem(item[0], item[1]);

            if (!flushResult.isSuccess) {
                failedItems.push(item);
            }
        }

        if (failedItems.length > 0) {
            this.queue.splice(0, 0, ...failedItems);
            this.handle = window.setTimeout(async () => this.flush());
        } else {
            this.handle = undefined;
        }
    }

    private async flushItem(item: T, action: ActionType): Promise<BasicResponse> {
        const missingActionResponse: BasicResponse = { isSuccess: true, message: null };

        switch (action) {
            case "create":
                if (this.createAction) {
                    return await this.createAction(item);
                } else {
                    return missingActionResponse;
                }

            case "update":
                if (this.updateAction) {
                    return await this.updateAction(item);
                } else {
                    return missingActionResponse;
                }

            case "delete":
                if (this.deleteAction) {
                    return await this.deleteAction(item);
                } else {
                    return missingActionResponse;
                }

            default:
                return missingActionResponse;
        }
    }
}

export const createPanelsUpdater = () => {
    const updatePanel = (panel: Panel) => repo.updatePanel(panel.id, {
        elementName: panel.elementName,
        installationSequence: null, // TODO
    });

    return new EntityUpdater<Panel>(undefined, updatePanel, undefined);
};

export const createCornersUpdater = () => {
    const updateCorner = (corner: Corner) => repo.updateCorner(corner.id, {
        elementName: corner.elementName,
        installationSequence: null, // TODO
    });

    return new EntityUpdater<Corner>(undefined, updateCorner, undefined);
};

export const createPanelTypeHooksUpdater = () => {
    const update = (panelType: PanelType<IPanelSource>) =>
        repo.updatePanelTypeHooks(panelType.id, { hooks: panelType.hooks });

    return new EntityUpdater<PanelType<IPanelSource>>(update, update, update);
};

export const createCornerPanelTypeHooksUpdater = () => {
    const update = (panelType: PanelType<ICornerPanelSource>) =>
        repo.updateCornerPanelTypeHooks(panelType.id, { hooks: panelType.hooks });

    return new EntityUpdater<PanelType<ICornerPanelSource>>(update, update, update);
};

export const createPanelTypeHooksBatchUpdater = () => {
    const update = (panelTypes: BatchUpdatePanelTypeHooksCommand[]) => repo.updatePanelTypeHooksBatch({ panelTypes });

    return new EntityUpdater<BatchUpdatePanelTypeHooksCommand[]>(update, update, update);
};

export const createCornerPanelTypeHooksBatchUpdater = () => {
    const update = (panelTypes: BatchUpdatePanelTypeHooksCommand[]) =>
        repo.updateCornerPanelTypeHooksBatch({ panelTypes });

    return new EntityUpdater<BatchUpdatePanelTypeHooksCommand[]>(update, update, update);
};
