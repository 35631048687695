import { useEffect, useRef, useState } from "react";
import { BasicItemResponse } from "../../responses/basicResponses";
import { ProcessingFailure } from "../processing-failure/processingFailure";
import { ProcessingPane } from "../processing-pane/processingPane";
import eventBus, { IApplicationEvent } from "../forge/eventBus/eventDispatcher";
import repo from "../../Repository";

export const ContentDownload = () => {
    const [processingInProgress, setProcessingInProgress] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        const startDownload = (url: string) => {
            const anchorElement = downloadLinkRef.current;

            if (!anchorElement) {
                return;
            }

            anchorElement.href = url;
            anchorElement.click();
        };

        const downloadBySignedUrl = async (createSignedUrl: () => Promise<BasicItemResponse<string>>) => {
            setProcessingInProgress(true);

            const signedUrlResponse = await createSignedUrl();

            setProcessingInProgress(false);

            if (!signedUrlResponse.isSuccess) {
                setFailureMessage(signedUrlResponse.message);

                return;
            }

            startDownload(signedUrlResponse.item);
        };

        const onDownloadModelConentRequested = (event: IApplicationEvent<string>) =>
            downloadBySignedUrl(() => repo.createGeneratedModelDownloadableUrl(event.payload));

        const onDownloadRevitHooksModelRequested = (event: IApplicationEvent<string>) =>
            downloadBySignedUrl(() => repo.createHooksGeneratedModelDownloadableUrl(event.payload));

        const onDownloadRevitFacadeModelRequested = (event: IApplicationEvent<string>) =>
            downloadBySignedUrl(() => repo.createFacadeModelDownloadableUrl(event.payload));

        const onDownloadQrCode = (event: IApplicationEvent<string>) => startDownload(event.payload);

        eventBus.addEventListener("Dextall.GeneratedModel.Download", onDownloadModelConentRequested);
        eventBus.addEventListener("Dextall.HooksRevitModel.Download", onDownloadRevitHooksModelRequested);
        eventBus.addEventListener("Dextall.RevitFacadeModel.UI.Download", onDownloadRevitFacadeModelRequested);
        eventBus.addEventListener("Dextall.QRCode.Download", onDownloadQrCode);

        return () => {
            eventBus.removeEventListener("Dextall.GeneratedModel.Download", onDownloadModelConentRequested);
            eventBus.removeEventListener("Dextall.HooksRevitModel.Download", onDownloadRevitHooksModelRequested);
            eventBus.removeEventListener("Dextall.RevitFacadeModel.UI.Download", onDownloadRevitFacadeModelRequested);
            eventBus.removeEventListener("Dextall.QRCode.Download", onDownloadQrCode);
        };
    }, []);

    // <a> is always hidden from user and controlled by code only
    /* eslint-disable jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/anchor-has-content */
    return <>
        <a ref={downloadLinkRef} style={{ display: "none" }} download={true} />

        {processingInProgress && <ProcessingPane
            isVisible={true}
            message="Preparing for download..."
            title="Download..."
        />}

        {failureMessage && <ProcessingFailure
            closeProcessingErrorDialog={() => setFailureMessage(null)}
            isVisible={true}
            title="Error"
            message={failureMessage} />}
    </>;
};