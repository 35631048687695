import { IWallWindowCellSource } from "./wallFace";

export interface IPanelSource {
    id: string;
    wallFaceId: string;
    box: THREE.Box3;
    openings: IPanelSourceOpening[];
    claddings: IPanelSourceCladdingCell[];
    origin: THREE.Vector3;
    offsetX: number;
    offsetY: number;
    thickness: number;
    userUniqueId: number;
    elementName: string;
    installationSequence: number | null;
    panelTypeId: string;
    customPanelTypeId: string | null;
    isParapetPanel: boolean;
}

export interface IStudioPanelSource extends IPanelSource {
    /** Studio panel type, available only when calling from DS endpoint */
    panelType: IStudioPanelSourceType | null;
}

export interface IStudioPanelSourceType {
    id: string;
    status: PanelStatus;
}

export enum PanelStatus {
    Created = 1,

    GeneratedSuccessfully = 2,

    GenerationFailed = 3,
}

export interface IPanelSourceOpening {
    box: THREE.Box3;
    cells: IWallWindowCellSource[];
    insideColor: number;
    outsideColor: number;
    profileStyle: number;
    windowSillColor: number;
}

export interface IPanelSourceCladdingCell {
    box: THREE.Box3;
    color: string;
    depth: number;
    style: CladdingCellStyle;
    thickness: number;
}

export enum CladdingCellStyle {
    // @deprecated This value is not supported by the engine
    Concealed = 1,

    Exposed = 2,

    Acm = 3,
}